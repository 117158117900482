<template lang="html">
	<v-container>
		<v-layout justify-center wrap>
			<v-flex
				:class="{
					'mt-5': $vuetify.breakpoint.mdAndUp,
					'mt-2': $vuetify.breakpoint.smAndDown,
				}"
				mb-3
				xs12
				text-xs-center
				class="title"
			>
				<v-icon
					:class="{
						heading: $vuetify.breakpoint.mdAndUp,
						smallHeading: $vuetify.breakpoint.smAndDown,
					}"
				>
					question_answer
				</v-icon>
				<span
					:class="{
						heading: $vuetify.breakpoint.mdAndUp,
						smallHeading: $vuetify.breakpoint.smAndDown,
					}"
					>FAQ
				</span>
				<span class="heading ml-0 hidden-sm-and-down">
					: Frequently asked questions
				</span>
			</v-flex>
			<v-flex xs12 md8>
				<v-expansion-panels accordian>
					<v-expansion-panel
						v-model="openState"
						expand
						focusable
						v-for="(item, i) in data"
						:key="i"
					>
						<v-expansion-panel-header>
							<div :style="faqQuestionStyles">
								{{ item.question }}
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<p v-for="line in item.answer" v-html="line"></p>
							<img v-if="item.image" :src="item.image" />
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			data: [
				{
					question: 'What are the stripes?',
					answer: [
						'The climate <a href="https://www.reading.ac.uk/planet/climate-resources/climate-stripes" target="_blank">warming stripes</a> were created by Professor Ed Hawkins at the University of Reading in 2018. Climate change has been found to get up to <a href="https://www.frontiersin.org/articles/10.3389/fevo.2017.00175/full" target="_blank">eight times</a> more coverage than biodiversity loss. Professor Miles Richardson (<a href="https://twitter.com/findingnature" target="_blank">@findingnature</a>) from the University of Derby created the biodiversity stripes in <a href="https://findingnature.org.uk/2022/08/10/biodiversity-stripes/" target="_blank">August 2022</a> and set up biodiversitystripes.info. Only by addressing both the warming climate and loss of wildlife do we stand a chance of passing on a stable planet for future generations.',
						'The ‘biodiversity stripes’ provide a visual representation of the change in biodiversity over time, often since 1970. The highest level of biodiversity is coloured bright green. Lower levels move through yellow to grey, depending upon the level of decline. Darker greys appear with greater declines.',
						'The original stripes used data from the <a href="http://stats.livingplanetindex.org/" target="_blank">Living Planet Index</a>. This data tells us that the population of mammals, birds, fish, amphibians and reptiles has seen an average drop of 69% globally since 1970. The global data includes over 30,000 populations of over 5000 species. The UK stripes use <a href="https://jncc.gov.uk/our-work/uk-biodiversity-indicators-2021/" target="_blank">JNCC UK biodiversity indicators</a>.',
					],
				},
				{
					question: 'Why does biodiversity matter?',
					answer: [
						'Biodiversity is the variety of life on Earth. It supports life and is fundamental in providing the air we breathe and food we eat. Humans evolved within a vibrant, biodiverse, natural world. It is inherently good and vital for our wellbeing. Yet through a disconnected relationship dominated by use and control of nature we have done great damage to the natural world. A spiralling breakdown as <a href="https://findingnature.org.uk/2022/06/06/factors-in-a-failing-relationship-with-nature/" target="_blank">when biodiversity decreases so does our relationship with nature</a>. A failing relationship that the <a href="https://www.unep.org/news-and-stories/press-release/new-unep-synthesis-provides-blueprint-urgently-solve-planetary" target="_blank">UN </a>recognise as the root cause of the environmental crises. Find out more about the human relationship with the rest of nature and how to improve it in <a href="https://findingnature.org.uk/2022/05/03/nature-connection-handbook/" target="_blank">The Nature Connection Handbook</a>, <a href="https://findingnature.org.uk/" target="_blank">finding nature blog</a> and the book <a href="https://pelagicpublishing.com/products/reconnection-relationship-nature-miles-richardson" target="_blank">Reconnection: Fixing our broken relationship with nature</a>.',
					],
					image: '/img/ncxhb.png',
				},
				{
					question: 'Why stripes?',
					answer: [
						'The stripes are a different way of showing the loss of wildlife. They are simple and capture attention, raising awareness of biodiversity loss and starting conversations. Measuring and indicating biodiversity is very complex, which can make telling a simple story difficult, but there is an urgent need to make the problem visible. People need to be aware for change to happen.',
					],
				},
				{
					question: 'Where have the stripes been used?',
					answer: [
						'Nature Positive at COP27. The global Nature Positive campaign led by <a href="https://nature4climate.org/" target="_blank">Nature4Climate</a> is using the stripes where they appeared at COP27 and <a href="https://nature4climate.org/the-landing-zone-for-cop15/" target="_blank">COP15</a>.',
						'The stripes were used to open an official <a href="https://twitter.com/pgISYEB/status/1583434767881867265?s=20&amp;t=1qJrN7Jol_nqrpFPzEk53A" target="_blank">seminar</a> by Scientist Philippe Grandcolas from the French National Centre for Scientific Research (CNRS) on climate change and biodiversity loss with French MPs in the French National Assembly.',
						'The biodiversity stripes have also appeared on Channel 4 News and in the Financial Times.',
					],
				},
				{
					question: 'What next?',
					answer: [
						'Over time we will add more stripes and more overlays for more regions and species. Get in touch if you can help.',
					],
				},
				{
					question: 'Can I use the stripes?',
					answer: [
						'Use of the biodiversity stripes is encouraged but must include appropriate acknowledgement of biodiversitystripes.info and the source of the data. When based on LPI data, LPI’s preferred format is: LPI 2022. Living Planet Index database. 2022 <a href="http://www.livingplanetindex.org/" target="_blank">www.livingplanetindex.org</a>). Note that products derived from LPI data for financial gain are prohibited without written permission of ZSL and WWF. UK stripes should acknowledge Department for Environment, Food and Rural Affairs, UK. 2021. UK Biodiversity Indicators 2021 and include the attribution statement "Contains public sector information from Defra, licenced under the Open Government Licence v3.0".',
						'The creator and licensor of the Forest Species Stripes is WWF-UK from ZSL/WWF (2022) <a href="https://livingplanetindex.org/fsi" target="_blank">Forest Specialist Index</a>, 1970 to 2018 data and published as <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank">CC BY-NC-SA 4.0</a>. Note that this product is not licenced for commercial use without written permission from WWF and ZSL',
					],
				},
				{
					question: 'How are the stripes created?',
					answer: [
						'The various data sets provide a figure for each year. The highest is coloured green, the lowest grey, via yellow. The amount of grey is linked to the level of decline as indicated in the figure below. The position of yellow also depends upon the level of decline - greater declines reach yellow sooner. Some datasets are quite smooth, the colour changes would be too subtle for stripes to emerge. For smooth data a random point between the high and low confidence intervals is used for each year.',
						'The biodiversity stripes weren’t created by a funded project with design expertise and user testing. However, the Coblis </span><a href="https://www.color-blindness.com/coblis-color-blindness-simulator/" target="_blank">colour blindness simulator</a> has been used to check how the stripes come through for those with colour vision deficiency.',
					],
					image: '/img/protocol.png',
				},
				{
					question: 'What are the forest stripes?',
					answer: [
						'The forest stripes are a visual representation of the change in population abundance of species that rely on forests from 1970-2018. The baseline representing the starting level of abundance is coloured in deep green. As the abundance declines on average, the lower levels move through yellow and orange, with red indicating the greatest decline since 1970. The stripes show an average decline of 79% between 1970 and 2018. The forest stripes use data from the<a href="https://livingplanetindex.org/fsi" target="_blank">Forest Specialist Index</a> (2022), part of the <a href="https://livingplanetindex.org/" target="_blank">Living Planet Index</a> dataset.',
					],
				},
				{
					question:
						'Why do the forest stripes use colours that are difficult for people with colour vision deficiency to see? The biodiversity stripes use green and grey to avoid this, why haven’t you taken the same approach?',
					answer: [
						'We only have until 2030 to change direction and meet our forest goals. The main purpose of the forest stripes is to share the urgency of the crisis facing our forests. WWF has deliberately chosen red as the most impactful colour to communicate this crisis, with green representing lush, healthy forests at the other end of the scale.',

						'In order to make sure this asset was still accessible for people with colour vision deficiency, WWF has used strong colours to make the contrast between the green and red as stark as possible, and made sure the red and green stripes weren’t sitting alongside each other. The asset has been tested in the <a href="https://www.color-blindness.com/coblis-color-blindness-simulator/" target="_blank">Coblis simulator</a> to ensure that the decline is still visible and accessible.',

						'WWF has also created a version of the forest stripes with species illustrations overlaid, so that the decline is visible through another means aside from the colours themselves.',
					],
				},
			],
			openState: [false],
		}
	},
	computed: {
		faqQuestionStyles() {
			return `font-size: ${this.$vuetify.breakpoint.mdAndUp ? 20 : 14}px;
        padding: ${this.$vuetify.breakpoint.mdAndUp ? 8 : 0}px;
        font-weight: 500;`
		},
		faqAnswerStyles() {
			return `padding: ${this.$vuetify.breakpoint.mdAndUp ? 25 : 16}px;
        font-size: ${this.$vuetify.breakpoint.mdAndUp ? 16 : 12}px;`
		},
	},
}
</script>
<style lang="css" scoped>
.container {
	height: 100%;
}

.title {
	text-align: center;
}

.heading {
	font-size: 24px;
	font-weight: 500;
}
.smallHeading {
	font-size: 20px;
	font-weight: 500;
	margin-left: 5px;
}

p {
	text-align: justify;
}

p:first-child::before {
	content: '»';
	font-weight: bold;
	font-size: 250%;
	line-height: 100%;
	color: teal;
	margin: 0 0.5rem 0 0;
	vertical-align: sub;
}

img {
	max-width: 100%;
	margin: auto;
	display: block;
}

.wsite-form-field div.wsite-form-input-container .wsite-form-input {
	box-sizing: border-box;
	width: 100% !important;
}

.wsite-form-input,
.wsite-search-element-input {
	margin-bottom: 10px;
	padding: 10px !important;
	background: #fafafa;
	color: #444444;
	border: 1px solid #b9b9b9;
	border-radius: 1px;
	font-family: 'Quattrocento Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.wsite-form-label {
	display: inline-block;
	padding: 10px 0 5px;
	color: #333333;
	font-family: 'Quattrocento Sans', sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: normal;
}

.wsite-form-label.wsite-form-fields-required-label {
	font-weight: normal;
	font-style: italic;
}

.form-required {
	display: inline;
	color: #ff2825 !important;
}

.submit-button {
	width: 100%;
	font-family: 'Roboto', sans-serif;
	padding: 0.5rem;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	background-color: rgb(245, 245, 245);
}

.submit-button:disabled {
	background-color: rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.26);
}
</style>
