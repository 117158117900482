var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-container"},[_c('v-tabs',{staticClass:"tabs",attrs:{"slider-color":"#ff6347","centered":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.imgTypeObj),function(imgType){return _c('v-tab',{key:imgType.id,attrs:{"link":true,"href":("#" + (imgType.id)),"disabled":_vm.tweening !== null},on:{"change":function($event){return _vm.tabSelected(imgType.id)}}},[_vm._v(" "+_vm._s(imgType.tabText)+" ")])}),1),_vm._l((_vm.imgTypeObj),function(imgType,i){return _c('img',{key:imgType.id,staticClass:"stripe-image",class:{
			main: _vm.isSelected(imgType.id),
			hidden: !_vm.isSelected(imgType.id) && !_vm.isPrevious(imgType.id),
			tweening: imgType.id === _vm.tweening,
		},attrs:{"src":_vm.publicPath + imgType.imgSource,"alt":("biodiversity stripes for " + (_vm.imageSettings.name))}})}),(_vm.lastUrl)?_c('img',{staticClass:"stripe-image last-image",attrs:{"src":_vm.publicPath + _vm.lastUrl}}):_vm._e(),_c('div',{staticClass:"download-button-styles"},[(_vm.sharing)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":_vm.$vuetify.breakpoint.xsOnly,"color":"#ff6347","fab":""},on:{"click":function($event){return _vm.shareFacebook()}}},on),[_c('v-icon',[_vm._v("facebook")])],1)]}}],null,false,4226486497)},[_c('span',{staticClass:"tool-tip"},[_vm._v("Share to facebook")])]):_vm._e(),(_vm.sharing)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":_vm.$vuetify.breakpoint.xsOnly,"color":"#ff6347","fab":""},on:{"click":function($event){return _vm.shareTwitter()}}},on),[_c('v-icon',[_vm._v("mdi-twitter")])],1)]}}],null,false,1819322380)},[_c('span',{staticClass:"tool-tip"},[_vm._v("Share to twitter")])]):_vm._e(),(_vm.sharing)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":_vm.$vuetify.breakpoint.xsOnly,"color":"#ff6347","fab":""},on:{"click":function($event){return _vm.shareLinkedIn()}}},on),[_c('v-icon',[_vm._v("mdi-linkedin")])],1)]}}],null,false,4083166028)},[_c('span',{staticClass:"tool-tip"},[_vm._v("Share to LinkedIn")])]):_vm._e(),(_vm.sharing)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":_vm.$vuetify.breakpoint.xsOnly,"color":"#ff6347","fab":""},on:{"click":function($event){return _vm.downloadImage(_vm.mainUrl)}}},on),[_c('v-icon',[_vm._v("file_download")])],1)]}}],null,false,250500978)},[_c('span',{staticClass:"tool-tip"},[_vm._v("Download")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":_vm.$vuetify.breakpoint.xsOnly,"color":"#ff6347","fab":""},on:{"click":function($event){return _vm.share()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.sharing ? 'mdi-close' : 'mdi-menu'))])],1)]}}])},[_c('span',{staticClass:"tool-tip"},[_vm._v(_vm._s(_vm.sharing ? 'Hide options' : 'Options'))])])],1),_c('div',{staticClass:"next-up"},[_c('div',{staticClass:"play-button-container"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('v-btn',_vm._g({class:{ on: _vm.$store.playing },attrs:{"x-small":"","color":"#ff6347"},on:{"click":_vm.togglePlay}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.$store.playing ? 'mdi-pause' : 'mdi-play'))])],1)]}}])},[_c('span',{staticClass:"tool-tip"},[_vm._v(_vm._s(_vm.$store.playing ? 'Stop slideshow' : 'Play slideshow'))])])],1),_c('p',[_vm._v(" "+_vm._s(_vm.nextText)+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }