import '@mdi/font/css/materialdesignicons.css'
import 'babel-polyfill'
import Vue from 'vue'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// import 'vuetify/src/stylus/app.styl'

import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition'

Vue.use(Vuetify)

const slideTransition = createSimpleTransition('my-transition')

Vue.component('my-transition', slideTransition)

import VueRouter from 'vue-router'
import App from './App.vue'
import { routes } from './routes.js'

Vue.use(VueRouter)

const router = new VueRouter({
	// base: process.env.NODE_ENV === 'production' ? '/showyournaturestripes/' : '/',
	base: '/',
	routes: routes,
	mode: 'history',
})

Vue.config.productionTip = false

import menuTree from './data/regionData.js'
Vue.prototype.$store = Vue.observable({
	menuTree,
	playing: false,
	nextImage: '',
	selectedRegion: 'global',
	selectedVariant: '',
	countdown: 6,
})

new Vue({
	router: router,
	render: (h) => h(App),
	vuetify: new Vuetify({ icons: { iconfont: 'mdi' } }),
}).$mount('#app')
