var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{staticClass:"title",class:{
				'mt-5': _vm.$vuetify.breakpoint.mdAndUp,
				'mt-2': _vm.$vuetify.breakpoint.smAndDown,
			},attrs:{"mb-3":"","xs12":"","text-xs-center":""}},[_c('v-icon',{class:{
					heading: _vm.$vuetify.breakpoint.mdAndUp,
					smallHeading: _vm.$vuetify.breakpoint.smAndDown,
				}},[_vm._v(" question_answer ")]),_c('span',{class:{
					heading: _vm.$vuetify.breakpoint.mdAndUp,
					smallHeading: _vm.$vuetify.breakpoint.smAndDown,
				}},[_vm._v("FAQ ")]),_c('span',{staticClass:"heading ml-0 hidden-sm-and-down"},[_vm._v(" : Frequently asked questions ")])],1),_c('v-flex',{attrs:{"xs12":"","md8":""}},[_c('v-expansion-panels',{attrs:{"accordian":""}},_vm._l((_vm.data),function(item,i){return _c('v-expansion-panel',{key:i,attrs:{"expand":"","focusable":""},model:{value:(_vm.openState),callback:function ($$v) {_vm.openState=$$v},expression:"openState"}},[_c('v-expansion-panel-header',[_c('div',{style:(_vm.faqQuestionStyles)},[_vm._v(" "+_vm._s(item.question)+" ")])]),_c('v-expansion-panel-content',[_vm._l((item.answer),function(line){return _c('p',{domProps:{"innerHTML":_vm._s(line)}})}),(item.image)?_c('img',{attrs:{"src":item.image}}):_vm._e()],2)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }