<template lang="html">
	<v-footer
		:class="{
			'pa-4': $vuetify.breakpoint.mdAndUp,
			'pt-1': $vuetify.breakpoint.smAndDown,
		}"
		:height="footer_height"
	>
		<v-layout row wrap align-center>
			<v-flex md5 xs12>
				<div
					:class="{
						'text-left body-1 ml-3': $vuetify.breakpoint.mdAndUp,
						'text-center body-1': $vuetify.breakpoint.smAndDown,
						'text-left caption': $vuetify.breakpoint.lg,
					}"
				>
					<span class="key hidden-sm-and-down"
						>Graphics and lead scientist:&nbsp;</span
					>
					<span class="key hidden-md-and-up">Lead scientist :&nbsp;</span>
					<a
						target="_blank"
						href="https://www.derby.ac.uk/staff/miles-richardson/"
						>Miles Richardson</a
					>
					<span class="value hidden-lg-and-down"
						>, School of Psychology, University of Derby Online Learning.</span
					>
				</div>
				<div
					:class="{
						'text-left ml-3': $vuetify.breakpoint.mdAndUp,
						'text-center': $vuetify.breakpoint.smAndDown,
						'text-left caption': $vuetify.breakpoint.lg,
					}"
				>
					<span class="key">Data:&nbsp;</span>
					<span
						>LPI 2022. Living Planet Index database; Department for Environment,
						Food and Rural Affairs, UK. 2021; &amp; UK Biodiversity Indicators
						2021</span
					>
				</div>
			</v-flex>
			<v-flex md5 offset-md2 xs-12>
				<div
					:class="{
						'text-right subheading mr-4': $vuetify.breakpoint.mdAndUp,
						'text-center subheading': $vuetify.breakpoint.smAndDown,
						'text-right caption': $vuetify.breakpoint.lg,
					}"
				>
					<span class="key">Website:&nbsp;</span>
					<a target="_blank" href="https://www.the-iea.org/">
						<img
							src="@/assets/IEA_favicon.png"
							class="iea_favicon"
							height="30"
							alt="Institute for Environmental Analytics logo"
						/>
						<span class="hidden-sm-and-down"
							>Institute for Environmental Analytics</span
						>
						<span class="hidden-md-and-up">IEA</span>
					</a>
				</div>
				<div
					:class="{
						'text-right subheading mr-4': $vuetify.breakpoint.mdAndUp,
						'text-center subheading': $vuetify.breakpoint.smAndDown,
						'text-right caption': $vuetify.breakpoint.lg,
					}"
				>
					<span class="key">Website funding:&nbsp;</span>
					<a target="_blank" href="https://www.ncas.ac.uk/">
						<span class="hidden-sm-and-down"
							>National Centre for Atmospheric Science</span
						>
						<span class="hidden-md-and-up">NCAS</span>
					</a>
				</div>
			</v-flex>
		</v-layout>
	</v-footer>
</template>

<script>
export default {
	computed: {
		footer_height() {
			let height = ''
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
				case 'sm':
				case 'md':
					height = 130
					break
				case 'lg':
					height = 70
					break
				case 'xl':
					height = 70
					break
			}
			return height
		},
	},
}
</script>

<style lang="css" scoped>
.iea_favicon {
	vertical-align: bottom;
}
.key {
	color: gray;
}
.value {
	color: white;
}

.text-center {
	font-size: 0.8rem !important;
}
</style>
