<template>
	<v-app light class="backgroundImage">
		<nav-bar></nav-bar>
		<transition :name="$route.name" mode="out-in">
			<router-view></router-view>
		</transition>

		<foooter></foooter>
		<sub-footer></sub-footer>
		<div class="cookie-warn" v-if="cookieWarn && !warningClosed">
			<p>
				We use optional third-party analytics cookies to understand how you use
				#BiodiversityStripes so we can build better products.
				<span class="close" @click="warningClosed = true">x</span>
			</p>
			<div class="buttons">
				<button @click="acceptCookies()">Accept</button>
				<button @click="declineCookies()">Reject</button>
			</div>
		</div>
	</v-app>
</template>

<script>
import Cookies from 'js-cookie'
import NavBar from './components/NavBar.vue'
import SubFooter from './components/SubFooter.vue'
import Foooter from './components/Footer.vue'

const COOKIE_NAME = 'allowGACookie'

export default {
	name: 'App',
	components: {
		NavBar,
		SubFooter,
		Foooter,
	},
	data() {
		return {
			warningClosed: false,
		}
	},
	computed: {
		cookieWarn() {
			return Cookies.get(COOKIE_NAME) === undefined
		},
	},
	methods: {
		acceptCookies() {
			gtag('consent', 'default', {
				analytics_storage: 'granted',
			})
			Cookies.set(COOKIE_NAME, 'true', { expires: 365 })
			this.warningClosed = true
		},
		declineCookies() {
			gtag('consent', 'default', {
				analytics_storage: 'denied',
			})
			Cookies.set(COOKIE_NAME, 'false', { expires: 365 })
			Cookies.remove('_ga_RD3GH837RK')
			this.warningClosed = true
		},
	},
	created() {
		// If we arrive at the root path, we want to play the slideshow
		// Otherwise, we can assume that a specific stripe has been linked to,
		// and we don't want to play the slideshow
		if (
			this.$route.params.region === undefined &&
			this.$route.name === 'stripes'
		) {
			this.$store.playing = true
		} else {
			this.$store.playing = false
		}

		const region = this.$route.params.region || 'global'
		if (region in this.$store.menuTree) {
			this.$store.selectedRegion = region
		} else {
			this.$store.selectedRegion = 'global'
		}

		const variant = this.$route.params.variant || ''
		if (variant in this.$store.menuTree[this.$store.selectedRegion].variants) {
			this.$store.selectedVariant = variant
		} else {
			this.$store.selectedVariant = ''
		}

		if (this.$route.name !== 'faq') {
			const params = {
				region: this.$store.selectedRegion,
			}
			if (this.$store.selectedVariant.length > 0) {
				params.variant = this.$store.selectedVariant
			}
			this.$router.push({
				name: 'stripes',
				params,
			})
		}

		const cookiesAllowed = Cookies.get(COOKIE_NAME)
		if (cookiesAllowed !== undefined) {
			if (cookiesAllowed === 'true') {
				gtag('consent', 'default', {
					analytics_storage: 'granted',
				})
			}
		}
	},
}
</script>

<style scoped>
.backgroundImage {
	/* Image downloaded from www.subtlepatterns.com */
	/* https://www.toptal.com/designers/subtlepatterns/prism/ */
	background-image: url('@/assets/img/prism.png');
}
.stripes-enter-active,
.stripes-leave-active,
.faq-enter-active,
.faq-leave-active {
	transition: opacity 0.5s, transform 0.5s;
}
.faq-enter,
.stripes-leave-to {
	opacity: 0;
	transform: translateX(3%);
}
.stripes-enter,
.faq-leave-to {
	opacity: 0;
	transform: translateX(-3%);
}
</style>

<style>
a {
	color: teal !important;
	text-decoration: none;
}

a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

div .primary--text {
	color: #ff6347 !important;
}

.cookie-warn {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	padding: 1rem;
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.cookie-warn .buttons {
	display: flex;
	justify-content: center;
	margin-top: 8px;
}

.cookie-warn .buttons button {
	border-radius: 2rem;
	box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.2);
	margin: 0 1rem;
	padding: 0.5rem 1rem;
}

.cookie-warn a,
.cookie-warn a:visited {
	color: var(--text-color);
	text-decoration: none;
}
.cookie-warn a:hover {
	color: var(--title-color);
}

.cookie-warn .buttons button {
	margin: 0 0 0 16px;
	color: var(--sidebar-color);
}

.cookie-warn .buttons button:hover {
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

span.close {
	color: red;
	margin-left: auto;
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	cursor: pointer;
}
</style>

<!-- <style>
#bbox-root {
  display: none;
}
</style> -->
