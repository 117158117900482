var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{class:{
		'pa-4': _vm.$vuetify.breakpoint.mdAndUp,
		'pt-1': _vm.$vuetify.breakpoint.smAndDown,
	},attrs:{"height":_vm.footer_height}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{attrs:{"md5":"","xs12":""}},[_c('div',{class:{
					'text-left body-1 ml-3': _vm.$vuetify.breakpoint.mdAndUp,
					'text-center body-1': _vm.$vuetify.breakpoint.smAndDown,
					'text-left caption': _vm.$vuetify.breakpoint.lg,
				}},[_c('span',{staticClass:"key hidden-sm-and-down"},[_vm._v("Graphics and lead scientist: ")]),_c('span',{staticClass:"key hidden-md-and-up"},[_vm._v("Lead scientist : ")]),_c('a',{attrs:{"target":"_blank","href":"https://www.derby.ac.uk/staff/miles-richardson/"}},[_vm._v("Miles Richardson")]),_c('span',{staticClass:"value hidden-lg-and-down"},[_vm._v(", School of Psychology, University of Derby Online Learning.")])]),_c('div',{class:{
					'text-left ml-3': _vm.$vuetify.breakpoint.mdAndUp,
					'text-center': _vm.$vuetify.breakpoint.smAndDown,
					'text-left caption': _vm.$vuetify.breakpoint.lg,
				}},[_c('span',{staticClass:"key"},[_vm._v("Data: ")]),_c('span',[_vm._v("LPI 2022. Living Planet Index database; Department for Environment, Food and Rural Affairs, UK. 2021; & UK Biodiversity Indicators 2021")])])]),_c('v-flex',{attrs:{"md5":"","offset-md2":"","xs-12":""}},[_c('div',{class:{
					'text-right subheading mr-4': _vm.$vuetify.breakpoint.mdAndUp,
					'text-center subheading': _vm.$vuetify.breakpoint.smAndDown,
					'text-right caption': _vm.$vuetify.breakpoint.lg,
				}},[_c('span',{staticClass:"key"},[_vm._v("Website: ")]),_c('a',{attrs:{"target":"_blank","href":"https://www.the-iea.org/"}},[_c('img',{staticClass:"iea_favicon",attrs:{"src":require("@/assets/IEA_favicon.png"),"height":"30","alt":"Institute for Environmental Analytics logo"}}),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Institute for Environmental Analytics")]),_c('span',{staticClass:"hidden-md-and-up"},[_vm._v("IEA")])])]),_c('div',{class:{
					'text-right subheading mr-4': _vm.$vuetify.breakpoint.mdAndUp,
					'text-center subheading': _vm.$vuetify.breakpoint.smAndDown,
					'text-right caption': _vm.$vuetify.breakpoint.lg,
				}},[_c('span',{staticClass:"key"},[_vm._v("Website funding: ")]),_c('a',{attrs:{"target":"_blank","href":"https://www.ncas.ac.uk/"}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("National Centre for Atmospheric Science")]),_c('span',{staticClass:"hidden-md-and-up"},[_vm._v("NCAS")])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }